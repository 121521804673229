import React from 'react';

import { grid, titleText, subtitleText, contentText, button, list } from './faq.module.scss';
import { ISection } from '../../models/section.model';
import { ISingleFaq } from '../../models/single-faq.model';
import { getLessImportantHeading } from '../../utils/get-less-important-heading';

import SingleFaq from '../molecules/single-faq';
import Section from '../hoc/section';

export interface IFaq extends ISection {
    items: {
        faqs: ISingleFaq[];
    };
}

interface IFaqProps {
    className?: string;
    section: IFaq;
    TitleTag?: React.ElementType;
}

const Faq: React.FC<IFaqProps> = ({ className = '', section, TitleTag = 'h2' }) => {
    const {
        sectionId,
        content: { texts },
        css,
        style,
        items: { faqs },
    } = section;
    const QuestionTag = getLessImportantHeading(TitleTag);

    return (
        <Section
            sectionId={sectionId}
            className={className}
            classes={{
                container: grid,
                title: titleText,
                subtitle: subtitleText,
                description: contentText,
                button: button,
            }}
            TitleTag={TitleTag}
            title={texts[0]}
            subtitle={texts[1]}
            description={texts[2]}
            css={css}
            style={style}
        >
            {faqs.length > 0 && (
                <ul className={list}>
                    {faqs.map((faq) => {
                        return (
                            <li key={`faq-list-item-${faq.faqId}`}>
                                <SingleFaq singleFaq={faq} QuestionTag={QuestionTag} />
                            </li>
                        );
                    })}
                </ul>
            )}
        </Section>
    );
};

export default Faq;
